import React from 'react';
import { NavLink } from 'react-router-dom';
import './GridWidget.css'; // Import the CSS file for styling

const gridItems = [
  { path: '/daily-bonus', name: 'Daily Bonus', image: require('../assets/daily.png') },
  { path: '/timeline', name: 'Timeline', image: require('../assets/clock.png') },
  { url: 'https://opensea.io/collection/hexa-miniature/overview', name: 'Mint NFT', image: require('../assets/9.png') },
  { path: '/contest', name: 'Contest', image: require('../assets/contest.png') },
];

const GridWidget = () => {
  return (
    <div className="grid-widget">
      {gridItems.map((item, index) => (
        item.url ? (
          <a
            href={item.url}
            key={index}
            className="grid-item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="icon">
              <img src={item.image} alt={item.name} className="grid-item-image" />
            </div>
            <div className="name">{item.name}</div>
          </a>
        ) : (
          <NavLink
            to={item.path}
            key={index}
            className="grid-item"
            activeClassName="active"
          >
            <div className="icon">
              <img src={item.image} alt={item.name} className="grid-item-image" />
            </div>
            <div className="name">{item.name}</div>
          </NavLink>
        )
      ))}
    </div>
  );
};

export default GridWidget;
