 import React, { useState, useEffect } from 'react';
import './FarmButton.css';
import { db } from '../../utils/Firebase';
import { doc, getDoc, updateDoc, setDoc, Timestamp, increment, getDocs, collection } from 'firebase/firestore';

import { FaRocket, FaUsers } from 'react-icons/fa';
import GridWidget from '../Widget';
import Loader from '../../utils/Loader';

const FarmComponent = ({userData, loading, telegramData, telegramId}) => {
  const [minedTokens, setMinedTokens] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [referCount, setReferCount] = useState(0);
  const [isFarming, setIsFarming] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [hasNFTBoost, setHasNFTBoost] = useState(false); // New state for NFT boost
  const maxTimeInSeconds = 43200; // 12 hours in seconds
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
 

 
  

  useEffect(() => {

    const ensureUserData = async () => {
      if (telegramId && userData) {
        const userRef = doc(db, 'users', telegramId);

        // Check if `balance` is undefined and set it to 0 if necessary
        if (userData.balance === undefined) {
          await setDoc(userRef, { balance: 10 }, { merge: true });
        }
      }
    };

    const init = async () => {
      setIsLoading(true);
      await ensureUserData();
      setIsLoading(false);
    };

    init();
  }, [telegramId, userData]);

 
  useEffect(() => {
    if (!telegramId) {
      console.error('No user is logged in');
      return;
    }

    const fetchFarmingData = async () => {
      if (userData) {
        setReferCount(userData.referralCount || 0);

        if (userData.startTime && userData.endTime && userData.isFarming) {
          const currentTime = Date.now();
          const startTimestamp = userData.startTime.seconds * 1000;
          const calculatedEndTime = userData.endTime.seconds * 1000;
          const elapsed = Math.floor((currentTime - startTimestamp) / 1000);

          setIsFarming(true);
          setEndTime(new Date(calculatedEndTime));
          setTimeElapsed(Math.min(elapsed, maxTimeInSeconds));
        }
      }
    };

   
  
    
  
  

    // Check if user has the NFT boost
    const checkNFTBoost = async () => {
      // Replace with logic to check if user owns the required NFT (using contract calls or external APIs)
      const hasNFT = await checkIfUserHasNFT(telegramData.id);
      setHasNFTBoost(hasNFT);
    };

    fetchFarmingData();
    checkNFTBoost();
  }, [telegramId, userData]);

  const checkIfUserHasNFT = async (userId) => {
    // Logic to check if user has the required NFT
    // You can integrate with an API like OpenSea or a smart contract to check NFT ownership.
    // For now, we simulate that the user has an NFT.
    return true; // Assuming the user always has the NFT (replace with real logic)
  };

  useEffect(() => {
    let timer;

    if (isFarming && timeElapsed < maxTimeInSeconds) {
      timer = setInterval(() => {
        setTimeElapsed((prev) => {
          const newElapsed = prev + 1;
          const newMinedTokens = (newElapsed * calculateMiningRate()) / 3600;
          setMinedTokens(newMinedTokens);
          return newElapsed;
        });
      }, 1000);
    } else if (timeElapsed >= maxTimeInSeconds) {
      setIsFarming(false);
    }

    return () => clearInterval(timer);
  }, [isFarming, timeElapsed, referCount]);

  const calculateMiningRate = () => {
    const baseRate = 0.05; // Base rate per hour
    const boost = 0.005 * referCount; // Boost of 0.005 per referral
    const nftBoost = hasNFTBoost ? 0.01 : 0; // NFT boost of 0.01 if the user has the NFT
    return baseRate + boost + nftBoost;
  };

  if (isLoading || !userData) {
    return <Loader />;
  }

  const startFarming = async () => {
    try {
      const userRef = doc(db, 'users', telegramId);
      const currentTime = new Date();
      const calculatedEndTime = new Date(currentTime.getTime() + maxTimeInSeconds * 1000);

      setIsFarming(true);
      setMinedTokens(0);
      setTimeElapsed(0);
      setEndTime(calculatedEndTime);

      await setDoc(
        userRef,
        {
          startTime: Timestamp.fromDate(currentTime),
          endTime: Timestamp.fromDate(calculatedEndTime),
          isFarming: true,
        },
        { merge: true }
      );
    } catch (error) {
      console.error('Error starting farming:', error);
    }
  };



  const claimTokens = async () => {
    try {
      const userRef = doc(db, 'users', telegramId);
      const totalMinedTokens = (timeElapsed * calculateMiningRate()) / 3600;
      const roundedTokens = parseFloat(totalMinedTokens.toFixed(3));
      await updateDoc(userRef, {
        balance: increment(roundedTokens),
        isFarming: false,
      });

    //  alert(`You have claimed ${totalMinedTokens.toFixed(6)} tokens!`);
      setMinedTokens(0);
      setTimeElapsed(0);
      setIsFarming(false);
    } catch (error) {
      console.error('Error claiming tokens:', error);
    }
  };

  const handleClick = () => {
    if (timeElapsed >= maxTimeInSeconds) {
      claimTokens();
    } else if (!isFarming) {
      startFarming();
    }
  };

  const progressPercentage = (timeElapsed / maxTimeInSeconds) * 100;

  const getButtonStyle = () => {
    if (timeElapsed >= maxTimeInSeconds) {
      return { background: 'rgb(141, 11, 228)' };
    } else if (isFarming) {
      return {
        background: `linear-gradient(to right, #8c00ff ${progressPercentage}%, #ccc ${progressPercentage}%)`,
      };
    } else {
      return { background: 'rgb(141, 11, 228)' };
    }
  };

  const { balance } = userData;

  const formatBalance = (balance) => {
    // Format balance with commas as thousand separators
    return balance.toLocaleString(undefined, {
      minimumFractionDigits: 2, // Ensure two decimal places
      maximumFractionDigits: 2,
    });
  };


  return (
    <div className='farm'>
    <div className="farm-container">
        <div className="home-grid">
          <div className="grid">
            <p>
              <img className="logob" src={require('../../assets/token.png')} alt="token" />{' '}
              {formatBalance(balance)}
            </p>
          </div>
       
        </div>
      <div className="farm-divs">
        <p><FaRocket/> <br/>{calculateMiningRate().toFixed(3)} <br/> HEXA/hr</p>
        <p><FaUsers/> <br/> {referCount}</p>
      </div>
      <GridWidget/>
     
      

      <button
        onClick={handleClick}
        className="farm-button"
        style={getButtonStyle()}
      >
        {isFarming
          ? `${minedTokens.toFixed(6)} HEXA`
          : timeElapsed >= maxTimeInSeconds
          ? `Claim - ${((timeElapsed * calculateMiningRate()) / 3600).toFixed(6)} HEXA`
          : 'Start Farming'}
      </button>
    </div>
    </div>
  );
};

export default FarmComponent;
