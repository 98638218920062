// Loader.js
import React from 'react';
import './Loader.css';
import loaderImage from '../assets/kash.png'; // Make sure to replace with your image path

const Loader = () => {
  return (
    <div className="loader">
      <img src={loaderImage} alt="Loading..." className="loader-image" />
    </div>
  );
};

export default Loader;
