import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Wallet from './components/Wallet';
import Task from './components/Task';
import Refer from './components/Refer';
import { db } from './utils/Firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import BottomNavBar from './components/BottomNav';
import './App.css';
import FarmComponent from './components/farm/Farm';

function App() {
  const [referralCode, setReferralCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [telegramId, setTelegramId] = useState(null);

  useEffect(() => {
    const initTelegramUser = async () => {
      try {
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.ready();
        } else {
          setError("Telegram WebApp is not available.");
          return;
        }

        const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
        if (telegramUser) {
          // Store user ID in local storage
          localStorage.setItem('telegramUserId', telegramUser.id.toString());
          setTelegramId(telegramUser.id); // Store telegramId for later use

          const userRef = doc(db, 'users', telegramUser.id.toString());
          const docSnap = await getDoc(userRef);

          if (!docSnap.exists()) {
            // Create new user if not found
            const newUser = {
              telegramId: telegramUser.id,
              username: telegramUser.username || '',
              balance: 10,
              streak: 0,
              referralCount: 0,
              level: 1,
              referralCode: referralCode || ''
            };
            await setDoc(userRef, newUser);
          } 

          // Fetch user data and set it in state
          const userDoc = await getDoc(userRef);
          setUserData(userDoc.data()); // Set userData state
        } else {
          setError("Telegram user data not found.");
        }
      } catch (error) {
        setError("Error initializing Telegram user: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    initTelegramUser();
  }, [referralCode]);

  if (loading) {
    return <div className="loading-screen">Loading...</div>;
  }

  if (error) {
    return <div className="error-screen">Error: {error}</div>;
  }

  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<FarmComponent userData={userData} telegramId={telegramId} />} />
          <Route path="/wallet" element={<Wallet userData={userData} telegramId={telegramId} />} />
          <Route path="/task" element={<Task userData={userData} telegramId={telegramId} />} />
          <Route path="/refer" element={<Refer userData={userData} telegramId={telegramId} />} />
        </Routes>
        <BottomNavBar />
      </div>
    </HashRouter>
  );
}

export default App;
